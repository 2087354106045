import PageControl, {FormPageProps, ModuleType} from "@/components/WdiBus/FormPage";
import React, {useEffect, useRef, useState} from "react";
import ProForm, {ProFormInstance} from "@ant-design/pro-form";
import {initApplyPageContext, ViewEntityProps} from "@/pages/financial/PoolPrepayReverseApply/components/data";
import {ResponseStatus} from "@/utils/request";
import {Button, Col, Form, Input, message, Row} from "antd";
import {WdiCardTitle, wdiCore, WdiModalAppResult, WdiModalProps} from "@/components/WdiControls";
import {Enum} from "@/enums/system";
import {WdiSelect} from "@/components/WdiControls/WdiSelect";
import TextArea from "antd/lib/input/TextArea";
import {WdiPanel} from "@/components/WdiControls/WdiPanel";
import {SoldToMaster} from "@/services/mdm/soldtomaster";
import {SoldTo} from "@/services/mdm/soldto";
import {EC} from "@/services/mdm/ec";
import {Product} from "@/services/mdm/product";
import {
  FormSpDetailApprovalPanel,
  PoolPrepayApprovalPanel
} from "@/pages/financial/PoolPrepayReverseApply/components/details";
import moment from "moment/moment";
import {WdiButtonContainer} from "@/components/WdiBus/Buttons";
import CancelApp from "@/pages/financial/PoolPrepayReverseApply/components/CancelApp";
import {PoolPrepayReverse} from "@/services/sales/financial/poolprepayreverse";
import InvoiceNoEditApp from "@/pages/financial/PoolPrepayReverseApply/components/InvoiceNoEditApp";
import {DataOptions} from "@/services/basic/metadata";
import {VatType} from "@/services/mdm/vattype";
import {SimpleStaff} from "@/services/basic/simplestaff";

const PoolPrepayReverseApprovalApp: React.FC<FormPageProps> = (props) => {
  const formRef = useRef<ProFormInstance>();
  const [entity, setEntity] = useState<ViewEntityProps>();
  const [modalProps, setModalProps] = useState<WdiModalProps>({visible: false});
  const [invoiceNoModalProps, setInvoiceNoModalProps] = useState<WdiModalProps>({visible: false});

  const {onClose} = props;
  const onModalClose = (result?: WdiModalAppResult) => {
    setModalProps({visible: false});
    setInvoiceNoModalProps({visible: false});
    props.callback?.();
  };

  const onSave = () => {
    PageControl.approval({
      moduleType: ModuleType.PoolPrepayReverse,
      data: {poolPrepayReverseId: props.data?.poolPrepayReverseId},
      callback: () => {
        props.callback?.();
      }
    });
    if (onClose) {
      onClose();
      props.callback?.();
    }
    setModalProps({visible: false});
    setInvoiceNoModalProps({visible: false});
  }
  useEffect(() => {
    (async () => {
      const params = props;
      if (params) {
        const result = await initApplyPageContext(params);
        if (result.code != ResponseStatus.SUCCESS) {
          message.error(result.message);
          return;
        }
        const viewEntity: ViewEntityProps = result.data;
        if (viewEntity.poolPrepayReverse) {
          const item = viewEntity.poolPrepayReverse;
          formRef.current?.setFieldsValue({
            prepayType: item.prepayType,
            year: item.year,
            amountPrepay: wdiCore.Number.AmountToString(item.amountPrepay, 2),
            amountReimbursement: wdiCore.Number.AmountToString(item.amountReimbursement, 2),
            amountPayment: wdiCore.Number.AmountToString(item.amountPayment, 2),
            remark: item.remark,
            invoiceNo: item.invoiceNo,
            createDate: item?.createdDate ? moment(item?.createdDate).format("YYYY-MM-DD") : undefined,
          });
          SoldToMaster.get(item?.soldToMasterCode).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                soldToMasterCode: res.data.codeOrName
              });
            }
          });
          SoldTo.get(item?.soldToCodePaid).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                soldToCodePaid: res.data.codeOrName
              });
            }
          });
          EC.get(item?.ecId).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                ecId: res.data.ecName
              })
            }
          });
          Product.get(item?.productLineId).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                productLineId: res.data.codeOrName
              })
            }
          });
          SimpleStaff.get(item?.createdUserId).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                createdUserName: res.data.staffName
              })
            }
          });
          DataOptions.search({typeGroup: 5, inValues: [item?.paymentMethodId].join()}).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                paymentMethodId: res.data[0].name
              })
            }
          });
          DataOptions.search({typeGroup: 106, inValues: [item?.invoiceType].join()}).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                invoiceType: res.data[0].name
              })
            }
          });
          VatType.get(item?.vatTypeId).then(res => {
            if (res.data) {
              formRef.current?.setFieldsValue({
                vatTypeId: res.data.vatTypeName
              })
            }
          });

        }
        setEntity(viewEntity);
      }
    })();
  }, [props.data]);
  useEffect(() => {
    if (props.setPageTitle && entity) {
      let title = `冲销记录【${entity.poolPrepayReverse?.poolPrepayReverseId}】`;
      if (entity.poolPrepayReverse?.statusId == 1) {
        title += `，待确认`;
      } else if (entity.poolPrepayReverse?.statusId == 2) {
        title += '，已确认';
      } else if (entity.poolPrepayReverse?.statusId == 4) {
        title += '，已作废';
      }
      props.setPageTitle(<div>
        <span>{title}</span>
        <WdiButtonContainer>
          {entity.confirmButtonHidden == true && <Button type='primary' onClick={async () => {
            if (window.confirm('确认要进行冲销吗?')) {
              await PoolPrepayReverse.confirm({formId: entity?.poolPrepayReverse?.poolPrepayReverseId}).then(response => {
                if (response.code == ResponseStatus.SUCCESS) {
                  message.success("操作成功！");
                  onSave();
                } else {
                  message.error(response.message);
                }
              });
            }
          }}>确认</Button>}
        </WdiButtonContainer>
        <WdiButtonContainer>
          {entity.buttonHidden == true && <Button type='primary' onClick={() => {
            setModalProps({
              visible: true,
              title: '作废原因',
              id: entity.poolPrepayReverse?.poolPrepayReverseId,
              data: {entity},
            });
          }}>
            作废
          </Button>}
        </WdiButtonContainer>
        <WdiButtonContainer>
          {entity.updateButtonHidden == true && <Button type='primary' onClick={() => {
            setInvoiceNoModalProps({
              visible: true,
              title: '修改发票号',
              id: entity.poolPrepayReverse?.poolPrepayReverseId,
              data: {entity},
            });
          }}>
            修改发票号
          </Button>}
        </WdiButtonContainer>
      </div>);
    }
  }, [props.setPageTitle, entity])
  return (
    <>
      <ProForm
        formRef={formRef}
        submitter={{
          render: () => {
            return (
              <></>
            );
          },
        }}
      >
        <WdiPanel collapsed={true} title={<WdiCardTitle iconType={Enum.IconType.FORM_SP} title='汇总信息'/>}>
          <Row gutter={[16, 24]}>
            <Col className='gutter-row' span={4}>
              <Form.Item name='soldToMasterCode' label='售达方主户'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='prepayType' label='预付类型'>
                <WdiSelect disabled={true} {...{
                  placeholder: '请选择凭证类型',
                  showSearch: true,
                  dataLoading: async () => {
                    return [
                      {label: '合同预付', value: 1},
                      {label: '活动预付', value: 2},
                    ];
                  },
                }} />
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='year' label='冲销年份'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='soldToCodePaid' label='售达方'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='ecId' label='费用项'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='productLineId' label='产品线'>
                <Input readOnly/>
              </Form.Item>
            </Col>

          </Row>
          <Row gutter={[16, 24]}>
            <Col className='gutter-row' span={4}>
              <Form.Item name='paymentMethodId' label='支付方式'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='invoiceType' label='发票类型'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4} hidden={entity?.inputHidden}>
              <Form.Item name='vatTypeId' label='税率类型'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4} hidden={entity?.inputHidden}>
              <Form.Item name='invoiceNo' label='发票号码'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='createDate' label='创建日期'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='createdUserName' label='创建人'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='amountPrepay' label='预付金额合计'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='amountReimbursement' label='可冲销付款金额合计'>
                <Input readOnly/>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={4}>
              <Form.Item name='amountPayment' label='本次付款金额'>
                <Input readOnly/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 24]}>
            <Col className="gutter-row" span={16}>
              <Form.Item name="remark" label="备注">
                <TextArea disabled rows={4}/>
              </Form.Item>
            </Col>
          </Row>
        </WdiPanel>
        <PoolPrepayApprovalPanel dataSource={entity?.poolPrepayDetails}/>
        <FormSpDetailApprovalPanel dataSource={entity?.formSpDetails}/>
      </ProForm>
      {modalProps.visible &&
        <CancelApp {...modalProps} onClose={onModalClose} onSave={onSave}/>
      }
      {invoiceNoModalProps.visible &&
        <InvoiceNoEditApp {...invoiceNoModalProps} onClose={onModalClose} onSave={onSave}/>
      }
    </>
  )
}
export default PoolPrepayReverseApprovalApp;
